const carrosselHome = document.querySelector('.home-banner__swiper');

if (carrosselHome) {

var swiperCarrossel = new Swiper('.home-banner__swiper', {
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
        },
        
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

      });
}


var allCarrosselCards  = document.querySelectorAll('.carrossel-cards');
const CarrosselCards = document.querySelector('.carrossel-cards__swiper');

if (CarrosselCards) {

  for (var elCarrosselCards of allCarrosselCards) { 
    // console.log(allCarrosselCards);
    // Array.prototype.indexOf.call(haystack, needle);
    var nCarrosselCards = Array.prototype.indexOf.call(allCarrosselCards, elCarrosselCards);

    var carousel = elCarrosselCards.querySelector('.carrossel-cards__swiper')
    var programasCarreiras = new Swiper(carousel, {
      spaceBetween: 20,
  slidesPerView: 'auto',
  centerInsufficientSlides: true,
  navigation: {
    nextEl: '.carrossel-cards__arrows .swiper-button-next',
    prevEl: '.carrossel-cards__arrows .swiper-button-prev',
  },
  autoplay: {
    pauseOnMouseEnter: true,
    delay: 3000,
  }

});
}
}

var allCarrosselCardsBlog  = document.querySelectorAll('.cards-blog');
const CarrosselCardsBlog = document.querySelector('.cards-blog__swiper');

if (CarrosselCardsBlog) {

  for (var elCarrosselCardsBlog of allCarrosselCardsBlog) { 
    var nCarrosselCardsBlog = Array.prototype.indexOf.call(allCarrosselCardsBlog, elCarrosselCardsBlog);

    var carouselBlog = elCarrosselCardsBlog.querySelector('.cards-blog__swiper')
    var programasCarreiras = new Swiper(carouselBlog, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.cards-blog__arrows .swiper-button-next',
        prevEl: '.cards-blog__arrows .swiper-button-prev',
      },
      pagination: {
        el: '.cards-blog .swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        480: {
          slidesPerView: 2,
        }, 
        768: {
          slidesPerView: 3,
        }
      }
    });
  }
}

var allCarrosselBigNumbers  = document.querySelectorAll('.slide-numbers');
const CarrosselBigNumbers = document.querySelector('.slide-numbers__swiper');

if (CarrosselBigNumbers) {

  for (var elCarrosselBigNumbers of allCarrosselBigNumbers) { 
    var nCarrosselBigNumbers = Array.prototype.indexOf.call(allCarrosselBigNumbers, elCarrosselBigNumbers);

    var carrosselBigNumbers = elCarrosselBigNumbers.querySelector('.slide-numbers__swiper')
    var programasCarreiras = new Swiper(carrosselBigNumbers, {
      spaceBetween: 20,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.slide-numbers__swiper .swiper-pagination',
        clickable: true,
      },
    });
  }
}

var allCarrosselVantagens  = document.querySelectorAll('.slide-vantagens');
const CarrosselVantagens = document.querySelector('.slide-vantagens__swiper');

if (CarrosselVantagens) {

  for (var elCarrosselVantagens of allCarrosselVantagens) { 
    var nCarrosselVantagens = Array.prototype.indexOf.call(allCarrosselVantagens, elCarrosselVantagens);

    var carVantagens = elCarrosselVantagens.querySelector('.slide-vantagens__swiper')
    var programasCarreiras = new Swiper(carVantagens, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.slide-vantagens__arrows .swiper-button-next',
        prevEl: '.slide-vantagens__arrows .swiper-button-prev',
      },
      // pagination: {
      //   el: '.slide-vantagens .swiper-pagination',
      //   clickable: true,
      // },
      breakpoints: {
        768: {
          slidesPerView: 3,
        }
      }
    });
  }
}

var allCarrosselCardIcone  = document.querySelectorAll('.slide-card-icone');
const CarrosselCardIcone = document.querySelector('.slide-card-icone__swiper');

if (CarrosselCardIcone) {

  for (var elCarrosselCardIcone of allCarrosselCardIcone) { 
    var nCarrosselCardIcone = Array.prototype.indexOf.call(allCarrosselCardIcone, elCarrosselCardIcone);
    var carCardIcone = elCarrosselCardIcone.querySelector('.slide-card-icone__swiper')
    var programasCarreiras = new Swiper(carCardIcone, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.slide-card-icone__arrows .swiper-button-next',
        prevEl: '.slide-card-icone__arrows .swiper-button-prev',
      },
      // pagination: {
      //   el: '.slide-card-icone .swiper-pagination',
      //   clickable: true,
      // },
    });
  }
}

var allCarrosselTimeline  = document.querySelectorAll('.timeline');
const CarrosselTimeline = document.querySelector('.timeline__swiper');

if (CarrosselTimeline) {

  for (var elCarrosselTimeline of allCarrosselTimeline) { 
    var nCarrosselTimeline = Array.prototype.indexOf.call(allCarrosselTimeline, elCarrosselTimeline);
    var anosTimeline = elCarrosselTimeline.querySelector('.timeline__anos');
    var carAnosTimeline = new Swiper(anosTimeline, {
      spaceBetween: 20,
      slidesPerView: 'auto',

    });

    var carTimeline = elCarrosselTimeline.querySelector('.timeline__swiper');
    var programasCarreiras = new Swiper(carTimeline, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      // centeredSlides: true,
      // loop: true,
      // loopedSlides: 2,
      scrollbar: {
          el: ".timeline .swiper-scrollbar",
          dragSize: 40,
          draggable: true,
        },
      thumbs: {
          swiper: carAnosTimeline,
        },
    });
  }
}


// Accessibility

sessionStorage.getItem('contrast') && +sessionStorage.getItem('contrast') && document.body.classList.add('has--high-contrast');

if (document.querySelector('[data-contrast]')) {
    document.querySelector('[data-contrast]').addEventListener('click', () => {
        document.body.classList.toggle('has--high-contrast');

        sessionStorage.setItem('contrast', document.body.classList.contains('has--high-contrast') ? 1 : 0);
    }, true);
}

if (sessionStorage.getItem('fontsize')) document.body.style.fontSize = sessionStorage.getItem('fontsize') + 'px';

if (document.querySelector('[data-fontsize-decrease]')) {
    document.querySelector('[data-fontsize-decrease]').addEventListener('click', (event) => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

        document.body.style.fontSize = --fontsize + 'px';

        sessionStorage.setItem('fontsize', fontsize);
    }, true);
}

if (document.querySelector('[data-fontsize-increase]')) {
    document.querySelector('[data-fontsize-increase]').addEventListener('click', (event) => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

        document.body.style.fontSize = ++fontsize + 'px';

        sessionStorage.setItem('fontsize', fontsize);
    }, true);
}



/*==VALIDACAO TAGUEAMENTO==*/
document.addEventListener('click', getElement); 

function getElement(){
  console.log(event.target);
}

/*==BTS MENU==*/

var btsMenu = document.querySelectorAll('[data-bt-menu]');
var boxMenu = document.querySelectorAll('[data-box-menu]');

for (var elBtMenu of btsMenu) {
    elBtMenu.addEventListener('click', abrirBoxMenu);
}

function abrirBoxMenu() {
  
	var nomeBotao = event.target.getAttribute('data-bt-menu');
	var elBoxMenuAtivo = document.querySelector('[data-box-menu="' + nomeBotao + '"]');
  var nomeMenuAtivo = elBoxMenuAtivo.getAttribute('data-box-menu');
	// elBoxMenuAtivo.classList.add('is--active');
  // elBoxMenuAtivo.classList.toggle('is--active');
  if (elBoxMenuAtivo.classList.contains('is--active')) {
    for (var elBoxMenu of boxMenu) {
      elBoxMenu.classList.remove('is--active');
    }
  } else {
    for (var elBoxMenu of boxMenu) {
      elBoxMenu.classList.remove('is--active');
    }
    elBoxMenuAtivo.classList.add('is--active');

  }
  document.body.classList.add('has--menu');

}

var btsFecharMenu = document.querySelectorAll('.hamburger__close');

for (var elBtsFecharMenu of btsFecharMenu) {
  elBtsFecharMenu.addEventListener('click', fecharBoxMenu);
}

function fecharBoxMenu() {
  for (var elBoxMenu of boxMenu) {
    elBoxMenu.classList.remove('is--active');
    document.body.classList.remove('has--menu');
  }
}

var menuHamburger = document.querySelectorAll('.hamburger .has--submenu');

for (var elMenuHamburger of menuHamburger) {
  elMenuHamburger.addEventListener('click', abrirHamburger);
}

function abrirHamburger() {
  event.target.classList.toggle('is--active');
}


/*==DETAILS FAKE - FOOTER==*/

var btDetails = document.querySelectorAll('[data-bt-details]');
var ulDetails = document.querySelectorAll('[data-ul-details]');

for (var elBtDetails of btDetails) {
  elBtDetails.addEventListener('click', abrirUlDetails);
}

function abrirUlDetails() {
  btDetailsAtivo = event.target.getAttribute('data-bt-details');
  var ulDetailsAtivo = document.querySelector('[data-ul-details="' + btDetailsAtivo + '"]');
  ulDetailsAtivo.classList.toggle('is--open');
  event.target.classList.toggle('is--open');
}


/*==FAQ==*/

var btsFaq = document.querySelectorAll('[data-faq-bt]');
var gridsFaq = document.querySelectorAll('[data-faq-grid]');

if (btsFaq) {
	for (var elBtsFaq of btsFaq) {
    	elBtsFaq.addEventListener('click', trocarFaq);
	}

	function trocarFaq() {
		for (var elBtsFaq of btsFaq) {
    		elBtsFaq.classList.remove('is--active');
		}
		for (var elGridsFaq of gridsFaq) {
			elGridsFaq.classList.remove('is--active');
		}
		var nomeFaq = event.target.getAttribute('data-faq-bt');
		event.target.classList.add('is--active');
		var elFaqAtivo = document.querySelector('[data-faq-grid="' + nomeFaq + '"]');
		elFaqAtivo.classList.add('is--active');
	}
}

/*==FAQ DETAILS==*/
// Fetch all the details element.
const details = document.querySelectorAll("details");

// Add the onclick listeners.
details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    // Close all the details that are not targetDetail.
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});


/*==MODAL CORRESPONDENTES==*/
var btsModalCorr = document.querySelectorAll('[data-bt-modal-corr]');
var modalsCorr = document.querySelectorAll('[data-modal-corr]');
var btsFecharModalCorr = document.querySelectorAll('.correspondentes__fechar');

if (btsModalCorr) {
  for (var elBtsModalCorr of btsModalCorr) {
    elBtsModalCorr.addEventListener('click', abrirModalCorr);
  }

  function abrirModalCorr() {
    var attModalCorr = event.target.getAttribute('data-bt-modal-corr');
    document.body.classList.add('has--modal');
    var elModalCorrAtivo = document.querySelector('[data-modal-corr="' + attModalCorr + '"]');
    elModalCorrAtivo.classList.add('is--active');
  }

  for (var elBtsFecharModalCorr of btsFecharModalCorr) {
    elBtsFecharModalCorr.addEventListener('click', fecharModalCorr);
  }

  function fecharModalCorr() {
    for (var elModalsCorr of modalsCorr) {
      elModalsCorr.classList.remove('is--active');
      document.body.classList.remove('has--modal');
    }
  }
}

/*==BLOCK DOWNLOAD SIMPLES==*/
var blockSelectSimples = document.querySelectorAll('.wp-block-lazyblock-seletor-downloads-simples');

if (blockSelectSimples) {
  for (var elBlockSelectSimples of blockSelectSimples) {
    var selectSelectSimples = elBlockSelectSimples.querySelector('.seletor-downloads');
    var btSelectSimples = elBlockSelectSimples.querySelector('.seletor-downloads__btn');
    selectSelectSimples.addEventListener('change', abrirArquivo);

    function abrirArquivo() {
      var linkBlockSelectSimples = selectSelectSimples.value;
      btSelectSimples.setAttribute('href', linkBlockSelectSimples);
      btSelectSimples.click();
    }
  }
}

/*==BLOCK DOWNLOAD FILTROS==*/
// var blockSelectFiltros = document.querySelector('.wp-block-lazyblock-seletor-downloads-filtro');

// if (blockSelectFiltros) {
//   var filtroSelect = blockSelectFiltros.querySelector('.seletor-downloads--filtros');
//   var arquivosSelect = blockSelectFiltros.querySelector('.seletor-downloads');

//   filtroSelect.addEventListener('change', filtrarArquivos);
//     function filtrarArquivos() {
//       var filtroAtivo = event.target.value;
//       var optsArquivosSelect = arquivosSelect.querySelectorAll('option');
//       if ( filtroAtivo == 'all') {
//         for (var elOptsArquivosSelect of optsArquivosSelect){
//           elOptsArquivosSelect.classList.remove('is--hidden');
//         }
//       } else {
//       for (var elOptsArquivosSelect of optsArquivosSelect){
//         elOptsArquivosSelect.classList.remove('is--hidden');
//         var filtroOpt = elOptsArquivosSelect.getAttribute('data-filtro');
//         console.log(filtroAtivo); /*  tá empacando depois daqui */
//         if (filtroOpt !== filtroAtivo ) {
//           elOptsArquivosSelect.classList.add('is--hidden');
//         } else {
//           elOptsArquivosSelect.classList.remove('is--hidden');
//         }
//       }
//     }
//   }

//     var btSelectArquivo = blockSelectFiltros.querySelector('.seletor-downloads__btn');
//     arquivosSelect.addEventListener('change', abrirArquivoFiltro);

//     function abrirArquivoFiltro() {
//       var linkBlockSelectArquivo = arquivosSelect.value;
//       btSelectArquivo.setAttribute('href', linkBlockSelectArquivo);
//       btSelectArquivo.click();
//     }

// }

var blockSelectFiltros = document.querySelector('.wp-block-lazyblock-seletor-downloads-filtro');

if (blockSelectFiltros) {
  var filtroSelect = blockSelectFiltros.querySelector('.seletor-downloads--filtros');
  var listaArquivosSelect = blockSelectFiltros.querySelector('.seletor-downloads--arquivos');
  var novoSelect = blockSelectFiltros.querySelector('.seletor-downloads');

  filtroSelect.addEventListener('change', filtrarArquivos);
    function filtrarArquivos() {
      while (novoSelect.firstChild) {
        novoSelect.removeChild(novoSelect.firstChild);
      }
      var filtroAtivo = event.target.value;
      var optsArquivosSelect = listaArquivosSelect.querySelectorAll('option');
      if ( filtroAtivo == 'all') {

        for (var elOptsArquivosSelect of optsArquivosSelect){
          novoSelect.add(elOptsArquivosSelect);
        }
      } else {
      for (var elOptsArquivosSelect of optsArquivosSelect){
        var filtroOpt = elOptsArquivosSelect.getAttribute('data-filtro');
        if (filtroOpt == filtroAtivo ) {
          console.log(elOptsArquivosSelect);
          novoSelect.add(elOptsArquivosSelect);
        } 
      }
    }
  }

    var btSelectArquivo = blockSelectFiltros.querySelector('.seletor-downloads__btn');
    novoSelect.addEventListener('change', abrirArquivoFiltro);

    function abrirArquivoFiltro() {
      var linkBlockSelectArquivo = novoSelect.value;
      btSelectArquivo.setAttribute('href', linkBlockSelectArquivo);
      btSelectArquivo.click();
    }

}


/*==FILTRO NOVIDADES==*/
var btFiltro = document.getElementById('busca_novidade');

if (btFiltro) {
  var filtroEstado = document.getElementById('filtro-estado');

  filtroEstado.addEventListener('change', filtrarCidades);
  var filtrosCidades = document.querySelectorAll('.filtro--cidade');

  function filtrarCidades() {
    var estadoAtivo = filtroEstado.value;
    
    for (var elFiltrosCidades of filtrosCidades){
      elFiltrosCidades.classList.remove('is--active');
    }
    var filtroCidadeAtivo = document.querySelector('[data-estado="' + estadoAtivo + '"]');
    var select = document.querySelector('[data-estado="' + estadoAtivo + '"]');
    filtroCidadeAtivo.classList.add('is--active');
  }

  

  for (var elFiltrosCidades of filtrosCidades){
    elFiltrosCidades.addEventListener('change', trocarUrl);

    function trocarUrl() {
      var cidadeSelecionada = event.target.value;
      var urlBusca = '/voce/novidades/' + cidadeSelecionada ;
      var urlInicial = btFiltro.getAttribute('data-link');
      var novaUrl = urlInicial + urlBusca;
      btFiltro.setAttribute('href', novaUrl);
      btFiltro.click();
    }
  }
}

/*==MENU ACESSIBILIDADE==*/

var btA11y = document.getElementById('btnA11y');
var boxA11y = document.querySelector('.a11y__box');

if (btA11y) {
  btA11y.addEventListener('click', toggleAcessibilidade);

  function toggleAcessibilidade() {
    boxA11y.classList.toggle('is--active');
    btA11y.parentElement.classList.toggle('is--active');
  }
}

/*==SCROLL CLICK AND DRAG==*/

const slider = document.querySelector('.scroll-drag');

if (slider) {
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
  isDown = true;
  slider.classList.add('active');
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
});
slider.addEventListener('mouseleave', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mouseup', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  slider.scrollLeft = scrollLeft - walk;
  console.log(walk);
});
}


/*==MODAL HOME==*/
var modalHome = document.getElementById('modal-home');

if(modalHome){

var btFecharModal = document.querySelector('.bt-close');
var playerModalVideo = document.getElementById('player-video');
document.body.classList.add('has--modal');
//add class body

btFecharModal.addEventListener('click', fecharModalVideo);


function fecharModalVideo() {
  modalHome.classList.remove('active');
  document.body.classList.remove('has--modal');
}
}
